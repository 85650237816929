import React from "react";
import logo from "../../../images/logo.png";
import "./style.scss";

function Linktree() {
  const links = [
    { link: "/", title: "Home" },
    {
      link: "https://mimamsa.chinmayavvdelhi.ac.in/",
      title: "Mimamsa Competition",
    },
    { link: "/chinmaya-vision-programme", title: "Chinmaya Vision Programme" },
    { link: "/school-events", title: "School Events" },
    { link: "/circulars", title: "School Circulars" },
    { link: "/cbse-results", title: "CBSE Results" },
    { link: "/press", title: "Press" },
    { link: "/e-newsletters", title: "E-Newsletters" },
    { link: "/contact-information/", title: "Connect" },
    { link: "/blog/", title: "Blog" },
  ];

  return (
    <main className="linktree">
      {/* <h1>Linktree</h1> */}
      <img className="linktree__logo" alt="chinmaya logo" src={logo}></img>
      <p className="linktree__logotext">
        Chinmaya Vidyalaya
        <br /> Vasant Vihar, Delhi
      </p>

      <div className="linktree__links">
        {links.map((item, i) => {
          return (
            <a
              key={`link-tree-button-${i}`}
              className="linktree__links__button"
              href={item.link}
            >
              {item.title}
            </a>
          );
        })}
      </div>
    </main>
  );
}

export default Linktree;
